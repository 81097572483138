import errorBag from '@/helpers/logging/errorBag.js'
import Logger from '@/helpers/logging/Logger.js'
import swal from '@/helpers/sweetalert/sweetalert.js'
import { CanceledError } from 'axios'
import { i18n } from '@/helpers/i18n.js'

/**
 * @param {object} [error]
 * @returns {void}
 */
export default function handleApiError(error) {
	if (error instanceof CanceledError) { return }

	if (error?.response?.data) {
		Logger.info('error.response.data found')

		swal({
			title: i18n.global.t('alerts.error'),
			icon: 'error',
			content: errorBag(error.response.data),
			buttons: false
		}).finally(() => {})
	} else {
		Logger.info('no error.response.data available')

		swal({
			title: i18n.global.t('alerts.error'),
			icon: 'error',
			text: error?.message ?? '',
			buttons: false
		}).finally(() => {})
	}
}
