import dayjs from 'dayjs'
import en from '@/translations/en.json'
import Logger from '@/helpers/logging/Logger.js'
import { createI18n } from 'vue-i18n'
import { datetimeFormats } from '@/translations/locales.js'

export const supportedLocales = [ 'de', 'da', 'en', 'es', 'fr', 'it', 'nl', 'pt' ]

export const i18n = createI18n({
	legacy: false,
	locale: 'en', // set locale
	fallbackLocale: import.meta.env.PROD ? 'en' : 'debug',
	messages: { en }, // set locale messages
	datetimeFormats
})

export async function setI18nLanguage(locale) {
	Logger.debug('setI18nLanguage', locale)
	// don't do anything when the language is not supported
	if (!supportedLocales.includes(locale)) { return }

	// if the language is not yet loaded in,
	if (!i18n.global.availableLocales.includes(locale)) {
		// async load it
		const messages = await import(`@/translations/${locale}.json`)
		Logger.debug(`@/translations/${locale}.json loaded`)
		// add it to the available locales
		i18n.global.setLocaleMessage(locale, messages.default)
	}

	// set i18n locale
	i18n.global.locale.value = locale
	// set localstorage locale
	localStorage.language = locale
	// set dayjs locale
	dayjs.locale(locale)
	// set dom locale
	document.querySelector('html').setAttribute('lang', locale)
}

/**
 * @type {object[]}
 */
export const languages = [
	{
		name: 'Nederlands',
		code: 'nl',
		dateRegEx: /\d{2}-\d{2}-\d{4}/,
		dateFormat: 'DD-MM-YYYY',
		dateSeparator: '-',
		dateCapture: /(?<day>\d{2})-(?<month>\d{2})-(?<year>\d{4})/,
	},
	{
		name: 'English',
		code: 'en',
		dateRegEx: /\d{1,2}\/\d{1,2}\/\d{4}/,
		dateFormat: 'M-D-YYYY',
		dateSeparator: '/',
		dateCapture: /(?<month>\d{1,2})\/(?<day>\d{1,2})\/(?<year>\d{4})/,
	},
	{
		name: 'Français',
		code: 'fr',
		dateRegEx: /\d{2}-\d{2}-\d{4}/,
		dateFormat: 'DD-MM-YYYY',
		dateSeparator: '-',
		dateCapture: /(?<day>\d{2})-(?<month>\d{2})-(?<year>\d{4})/,
	}
]
