import axios from 'axios'
import axiosHttpClient from '@/helpers/axiosHttpClient.js'
import httpStatus from '@/helpers/httpStatus.js'
import Logger from '@/helpers/logging/Logger.js'
import { getHeaders } from '@/helpers/oauth.js'
import { useAuthorizationStore } from '@/stores/useAuthStore.js'

/**
 * Auth class
 */
class Auth {
	/**
	 * @param {string} loginLocation
	 * @param {string} redirectAfterClearLog
	 * @param {string} redirectAfterAuthenticated
	 */
	constructor(
		loginLocation,
		redirectAfterClearLog,
		redirectAfterAuthenticated
	) {
		// Login location
		this.loginLocation = loginLocation
		// Path to redirect to
		this.getRedirectUrl(redirectAfterClearLog)
		// Path to redirect after logging in
		this.redirectAfterAuthenticated = redirectAfterAuthenticated

		axios.interceptors.response.use(
			null,
			function (error) {
				return new Promise(() => {
					Logger.debug('axios.interceptors.response', error)
					if (error?.response?.status === httpStatus.UNAUTHORIZED && !error.response.request.responseURL.endsWith(this.loginLocation)) {
						useAuthorizationStore().logout()
					}
					throw error
				})
			}
		)
	}

	/**
	 * @param {object} response
	 * @param {object} response.data
	 * @returns {object} an object of token data
	 */
	static createTokensFromResponse(response) {
		const data = response.data
		const now = new Date().getTime() / 1000
		return {
			...data,
			expires_at: now + data.expires_in
		}
	}

	/**
	 * @param {string} token
	 * @returns {any} the token
	 */
	static parseJwt(token) {
		const base64Url = token.split('.')[1]
		const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
		const jsonPayload = decodeURIComponent(
			atob(base64)
				.split('')
				.map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`)
				.join('')
		)

		return JSON.parse(jsonPayload)
	}

	/**
	 * @param {string} token
	 * @returns {boolean} whether the user signed in with a token
	 */
	static userSignedInWithToken(token) {
		const jwt = this.parseJwt(token)
		return !!jwt['tkn']
	}

	/**
	 * Try to log in the user through their credentials
	 * @param {string} username
	 * @param {string} password
	 * @returns {Promise} a promise to act upon
	 */
	login(username, password) {
		return new Promise((resolve, reject) => {
			const credentials = { username, password }

			axiosHttpClient
				.post('auth/login', credentials, { headers: getHeaders(null) })
				.then(response => resolve(Auth.createTokensFromResponse(response)))
				.catch(reject)
		})
	}

	/**
	 * Try to log in the user through a token
	 * @param {string} token
	 * @returns {Promise} a promise to act upon
	 */
	loginToken(token) {
		return new Promise((resolve, reject) => {
			const credentials = { token }
			axiosHttpClient
				.post('auth/token/check', credentials, { headers: getHeaders(null) })
				.then(response => resolve(Auth.createTokensFromResponse(response)))
				.catch(reject)
		})
	}

	/**
	 * Try to register a user with a token
	 * @param {any} router
	 * @param {object} data
	 * @returns {Promise} the promise to act upon
	 */
	registerWithToken(router, data) {
		return new Promise((resolve, reject) => {
			const credentials = {
				token: data.token,
				username: data.username,
				password: data.password,
				password_confirmation: data.password_confirmation,
				first_name: data.first_name,
				middle_name: data.middle_name,
				last_name: data.last_name,
				email: data.email,
				user_type: data.user_type,
				user_language: data.user_language,
				system_language: data.system_language
			}
			axiosHttpClient.post(
				`${import.meta.env.VITE_API_URL}/api/v1/auth/token/register`,
				credentials
			)
				.then(() => router.push(this.redirectAfterClearLog))
				.catch(reject)
		})
	}

	/**
	 * @param {any} router
	 * @param {object} tokens
	 * @returns {Promise} the promise to act upon
	 */
	setTokens(router, tokens) {
		return new Promise((resolve) => {
			if (tokens.access_token && tokens.expires_in && tokens.token_type) {
				resolve()
			} else {
				router.push(this.redirectAfterClearLog).then(() => {})
			}
		})
	}

	/**
	 * @param {string} url
	 */
	getRedirectUrl(url) {
		this.redirectAfterClearLog = url
	}
}

/**
 * @returns {string} the generated password
 */
export function generatePassword() {
	const bigAssNumber = 1e30 // 1 * 10 ^ 30
	const base36 = 36
	const thresholdToIntroduceSpecialChar = 0.89
	const specialChars = [ ' ', '&', '_', '-', '#', '=', '+' ]
	const newPassword = (Math.random() * (Math.random() * bigAssNumber) + bigAssNumber).toString(base36)
		.split('')
		.map(character => {
			let char = character
			if (Math.random() > 0.5) {
				char = char.toUpperCase()
			}
			if (Math.random() > thresholdToIntroduceSpecialChar) {
				return specialChars[Math.floor(Math.random() * specialChars.length)]
			}
			return char
		})
		.join('')
	return newPassword
}

export default Auth
