import cloneDeep from 'lodash.clonedeep'
import { ref } from 'vue'

let instance
let logs = []
let _isLogging = false
const retain = 10
const updatedAt = ref(Date.now())

/* eslint-disable */
class Logger {
	constructor() {
		if (instance) {
			throw new Error('You can only create one instance')
		}
		instance = this
		logs = []
	}

	get lastUpdated() {
		return updatedAt.value
	}

	_addLog(level = 'log') {
		if (_isLogging) {
			console.error('Logger was already loggin\' mate')
			return // Avoid recursion
		}
		_isLogging = true

		try {
			updatedAt.value = Date.now()
			const message = [...arguments].slice(1)
			if (localStorage?.debug === 'true') {
				// eslint-disable-next-line no-console
				console[level](...message)
			}
			const logEntry = { level, content: [...message].join(', '), timestamp: Date.now() }
			logs.push(logEntry)

			// Retain only the last N logs
			if (logs.length > retain) {
				logs = logs.slice(-retain)
			}
		} finally {
			_isLogging = false
		}
	}

	log(...message) {
		this._addLog('log', ...message)
	}

	debug(...message) {
		this._addLog('debug', ...message)
	}

	error(...message) {
		this._addLog('error', ...message)
	}

	info(...message) {
		this._addLog('info', ...message)
	}

	getLastLogs() {
		return cloneDeep(logs).reverse()
	}

	clear() {
		console.clear()
		updatedAt.value = Date.now()
		logs = []
	}
}

const singletonLogger = Object.freeze(new Logger())

export default singletonLogger
/* eslint-enable */
