import axiosHttpClient from '@/helpers/axiosHttpClient.js'
import handleApiObject from '@/helpers/logging/handleApiObject.js'
import Logger from '@/helpers/logging/Logger.js'
import { computed, ref } from 'vue'
import { defaultExpiresTime, errorRetryTime, nowTime } from '@/stores/storeDefaults.js'
import { defineStore } from 'pinia'
import { useAuthorizationStore } from '@/stores/useAuthStore.js'
import { useStorage } from '@vueuse/core'

export const useSettingsStore = defineStore('settings', () => {
	const settings = useStorage('settings', {}, sessionStorage)
	const loading= ref(false)
	const expires = useStorage('settings-expires', null, sessionStorage)

	function get(module, setting) {
		fetchSettings()

		if (module !== null && setting !== null) {
			return settings.value?.[module]?.[setting]
		} else if (module !== null) {
			return settings.value?.[module]
		} else {
			return settings.value
		}
	}

	const list = computed(() => {
		fetchSettings()
		return settings.value
	})

	/**
	 * Fetch settings
	 * @param {boolean} [force]
	 * @returns {Promise} A promise to act upon
	 */
	async function fetchSettings(force = false) {
		// if the request is already loading and we're not forcing
		if (!force && loading.value) { return }
		// if the data has not yet expired and we're not forcing
		if (!force && new Date().getTime() < expires.value) { return }
		// if the user is not logged in
		if (!useAuthorizationStore().accessToken()) { return }

		loading.value = true
		await axiosHttpClient
			.get('settings')
			.then(response => {
				const data = response?.data?.data ?? []
				settings.value = data.reduce((acc, currentSetting) => {
					acc[currentSetting.module] ??= {}
					acc[currentSetting.module][currentSetting.key] = currentSetting.value
					return acc
				}, {})
				expires.value = nowTime() + defaultExpiresTime
			})
			.catch(e => {
				Logger.debug('useSettingsStore: fetch failed')
				expires.value = nowTime() + errorRetryTime
				handleApiObject(e)
			})
			.finally(() => { loading.value = false })
	}

	/**
	 * Clean up the data
	 */
	function remove() {
		Logger.debug('useSettingsStore:remove')
		loading.value = false
		settings.value = []
		expires.value = 0
	}

	return {
		settings,
		loading,
		expires,
		get,
		list,
		fetch: fetchSettings,
		remove
	}
})
