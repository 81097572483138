import 'core-js/actual'

import '@/helpers/fontAwesome.js'
import 'wicg-inert'
import { createApp } from 'vue'
import { createMetaManager } from 'vue-meta'
import { createPinia } from 'pinia'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
import { i18n as internalization } from '@/helpers/i18n.js'
import { tooltipDirective } from '@/helpers/directive-tooltip.js'
import { useAuthorizationStore } from '@/stores/useAuthStore.js'
import * as Sentry from '@sentry/vue'
import App from './App.vue'
import Auth from '@/helpers/Auth.js'

import pack from '../package.json'
import PortalVue from 'portal-vue'
import router from './router/routes.js'
import VueMatomo from 'vue-matomo'

const app = createApp(App)
app.use(createPinia())
app.use(router)
app.use(createMetaManager())
app.use(internalization)
app.use(PortalVue)
app.use(VueMatomo, {
	host: 'https://statistics.carenet.dev',
	siteId: 1,
	router,
	enableLinkTracking: true
})

app.component('FontAwesomeIcon', FontAwesomeIcon)
app.component('FontAwesomeLayers', FontAwesomeLayers)

if (import.meta.env.VITE_SENTRY_DSN && import.meta.env.VITE_SENTRY_ENV && import.meta.env.VITE_SENTRY_ENV !== 'local') {
	// Sentry.io error logging
	Sentry.init({
		app,
		dsn: import.meta.env.VITE_SENTRY_DSN,
		integrations: [
			Sentry.browserTracingIntegration({ router }),
			Sentry.replayIntegration({ maskAllText: false })
		],
		replaysSessionSampleRate: 0.05,
		replaysOnErrorSampleRate: 0.5,
		tracesSampleRate: Number(import.meta.env.VITE_SENTRY_SAMPLE_RATE ?? 0),
		release: `nfo-frontend@${pack.version}`,
		environment: import.meta.env.VITE_SENTRY_ENV,
		trackComponents: true
	})
}

app.config.globalProperties.$http = new Auth(`${import.meta.env.VITE_API_URL}/api/v1/auth/login`, '/login', '/')
app.config.globalProperties.$usertype = (type) => {
	const auth = useAuthorizationStore()
	return auth.isOfUserType(type)
}
app.config.globalProperties.$t = internalization.global.t

app.directive('tip', tooltipDirective)
app.directive('focus', { mounted: el => el.focus() })

app.mount('#root')

export default app
