import axiosHttpClient from '@/helpers/axiosHttpClient.js'
import doNothing from '@/helpers/api/doNothing.js'
import handleApiError from '@/helpers/logging/handleApiError.js'
import Logger from '@/helpers/logging/Logger.js'
import router from '@/router/routes.js'
import { defineStore } from 'pinia'
import { getHeaders } from '@/helpers/oauth.js'
import { useFeaturesStore } from '@/stores/useFeaturesStore.js'
import { useModulesStore } from '@/stores/useModulesStore.js'
import { usePermissionsStore } from '@/stores/usePermissionsStore.js'
import { usePreferencesStore } from '@/stores/usePreferencesStore.js'
import { useSettingsStore } from '@/stores/useSettingsStore.js'
import { useStorage } from '@vueuse/core'

export const useAuthorizationStore = defineStore('authorization', {
	state: () => ({
		/**	@type {object} */
		authorization: useStorage('authorization', {}, localStorage),
		loading: false,
		expires_at: useStorage('authorization-expires-at', 0, localStorage)
	}),

	getters: {
		accessToken(state) {
			return () => {
				return state.authorization?.access_token ?? false
			}
		},

		/**
		 * @param {object} state
		 * @returns {function(): {}} returns the user
		 */
		user(state) {
			return () => {
				return state.authorization?.user ?? {}
			}
		},

		expires(state) {
			return () => {
				return state?.expires_at ?? 0
			}
		},
	},

	actions: {
		/**
		 * Login the user
		 * @param {string} username
		 * @param {string} password
		 * @returns {Promise} A promise to act upon
		 */
		login(username, password) {
			Logger.debug('authStore.login')
			const credentials = { username, password }

			this.loading = true
			return axiosHttpClient
				.post(
					'auth/login',
					credentials,
					{ headers: getHeaders() }
				)
				.then(response => {
					Logger.debug('login was successful')
					const now = Math.floor(new Date().getTime() / 1000)
					this.authorization = response.data
					this.expires_at = now + response.data.expires_in
				})
				.finally(() => {
					this.loading = false
				})
		},

		/**
		 * Logout the user
		 */
		logout() {
			Logger.debug('useAuthStore:logout: Received a logout instruction')
			this.remove()
			router.push({ name: 'auth.login' }).catch(doNothing)
			useFeaturesStore().remove()
			useModulesStore().remove()
			usePermissionsStore().remove()
			usePreferencesStore().remove()
			useSettingsStore().remove()

			// Try this, but it's not necessary if the session has already run out
			axiosHttpClient.post('auth/logout').catch(doNothing)
		},

		/**
		 * Refresh the token
		 * @returns {Promise} A promise to act upon
		 */
		refresh() {
			this.loading = true
			return axiosHttpClient
				.post('auth/refresh')
				.then(response => {
					const data = response?.data ?? {}
					const now = new Date().getTime() / 1000
					this.authorization.access_token = data?.access_token
					this.authorization.token_type = data?.token_type
					this.authorization.expires_in = data?.expires_in
					this.expires_at = now + data.expires_in
				})
				.catch(handleApiError)
				.finally(() => { this.loading = false })
		},

		/**
		 * Remove data from localStorage and the store
		 */
		remove() {
			localStorage.removeItem('auth')
			this.loading = false
			this.authorization = {}
			this.expires_at = 0
		},

		/**
		 * @param {string} type
		 * @returns {boolean} whether the user is of this type
		 */
		isOfUserType(type) {
			return type === this.authorization?.user?.user_type
		}
	}
})
